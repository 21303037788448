.footer {
    background-color: $primary;
    color: color(white);
    font-size: 14px;
    height: auto;
    margin-top: auto;
    padding: 0;
    a {
        color: color(white);
        font-size: 14px;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
    .social-networks {
        a {
            &:hover {
                svg {
                    color: color(white);
                    &.fa-circle {
                        color: color(primary);
                    }
                    transition: color linear .3s;
                }
            }
        }
    }
    .menu {
        &-bottom {
            column-count: 2;
            column-gap: 20px;
        }
    }
}
