.background {
    @each $name in $colorNames {
        &-#{"" + $name} {
            background-color: color($name) !important;
        }
    }
}
.background-hover {
    @each $name in $colorNames {
        &-#{"" + $name} {
            &:hover {
                background-color: color($name) !important;
            }
        }
    }
}
