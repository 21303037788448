.navbar {
    &#menu-top {
        height: $header_height;
        @include media-breakpoint-down(lg) {
            height: 50px;
            box-shadow: 0 2px 20px #2d2a25;
            position: fixed;
            top: 0;
            width: 100%;
            z-index: 10;
        }
    }
    &-brand {
        height: $header_height;
        @include media-breakpoint-down(lg) {
            height: 50px;
        }
    }
    &.navbar-light .navbar-nav {
        &.menu {
            &-block {
                padding: 0;
                margin: 0;
                @include media-breakpoint-up(lg) {
                    display: flex;
                    height: 90px;
                }
                &-flat {
                    .menu {
                        &-link {
                            display: block;
                        }
                    }
                }
                @include media-breakpoint-up(lg) {
                    &-depth1 {
                        gap: 20px;
                        > .submenu > .menu-sub {
                            top: $header_height - 2px;
                            transform: translateX(255px);
                        }
                    }
                    &-depth2, &-depth3 {
                        > .submenu {
                            position: relative;
                            width: 100%;
                        }
                    }
                }
            }
        }
        .menu {
            height: $header_height;
            @include media-breakpoint-down(lg) {
                height: 50px;
            }
            position: relative;
            @include media-breakpoint-down(lg) {
                background-color: $primary;
            }
            &-sub {
                @include media-breakpoint-up(lg) {
                    display: none;
                    position: absolute;
                    width: 250px;
                    left: 0;
                    top: 100%;
                    min-height: 80px;
                    background: $primary;
                    box-shadow: 0 5px 5px rgb(0 0 0 / 20%);
                    .menu-block {
                        height: auto;
                        position: relative;
                    }
                    .menu-link {
                        color: #FFF;
                    }
                    .menu-sub {
                        background: darken($primary, 5%);
                        left: 100%;
                        min-height: 40px;
                        top: 0;
                    }
                }
                @include media-breakpoint-down(lg) {
                    padding-left: 15px;
                    ul {
                        padding-left: 15px;
                    }
                }
            }
            &-block {
                padding: 0;
                margin: 0;
                @include media-breakpoint-up(lg) {
                    display: flex;
                    height: 90px;
                }
                &-flat {
                    .menu {
                        &-link {
                            display: block;
                        }
                    }
                }
                @include media-breakpoint-up(lg) {
                    &-depth1 {
                        gap: 20px;
                        > .submenu > .menu-sub {
                            top: $header_height - 2px;
                            transform: translateX(255px);
                        }
                    }
                    &-depth2, &-depth3 {
                        > .submenu {
                            position: relative;
                            width: 100%;
                        }
                    }
                }
            }
            &-item {
                > a {
                    padding: 15px 0;
                }
                @include media-breakpoint-up(lg) {
                    display: flex;
                    flex: 1 0 auto;
                    height: 100%;
                    &:hover {
                        & > .menu-sub {
                            z-index: 1000;
                            display: block;
                        }
                        & > .menu-link {
                            color: $custom-grey-2;
                        }
                    }
                }
                padding: 0;
                list-style: none;
                &-flat {
                    display: block;
                    .menu-link {
                        justify-content: start;
                    }
                }
            }
            &-link {
                @include media-breakpoint-up(lg) {
                    display: flex;
                }
                @include media-breakpoint-down(lg) {
                    color: #FFF;
                    display: block;
                    padding: 10px;
                    border-bottom: 1px solid #FFF;
                }
                font-size: 18px;
                font-weight: 500;
                width: 100%;
                align-items: center;
                justify-content: center;
                color: $primary;
                text-decoration: none;
            }
        }
    }
    app-menu-parts {
        @include media-breakpoint-down(lg) {
            width: 100%;
        }
    }
    .offcanvas {
        @include media-breakpoint-down(sm) {
            --bs-offcanvas-width: 100%;
        }
    }
    .customer-actions {
        a {
            font-size: 14px;
            @include media-breakpoint-down(sm) {
                &.btn.btn-outline-primary {
                    border-color: #ffffff;
                    color: #ffffff;
                }
            }
        }
    }
    a.cart-btn {
        display: flex;
        position: relative;
        &.created {
            &:before {
                content: ' ';
                background-color: $primary;
                border-radius: 30px;
                display: block;
                height: 100%;
                left: 50%;
                position: absolute;
                top: 0;
                transition: left .3s;
                width: 50%;
            }
            &.not-empty {
                &:before {
                    left: 55%;
                    width: 45%;
                }
            }
        }
        span {
            align-self: center;
            display: flex;
            position: relative;
            transition: all ease .2s;
            width: max-content;
            z-index: 2;
        }
    }
}
