app-layout {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    position: relative;
}
.layout {
    &-container {
        height: max-content;
        padding-bottom: 10px;
    }
}
