form {
    &.needs-validation {
        &.ng-invalid {
            .form-control {
                &.ng-dirty.ng-invalid {
                    border-color: var(--bs-danger);
                }
            }
        }
    }
}
