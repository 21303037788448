html, body {
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    height: 100%;
}
body {
    overflow-x: hidden;
    @include media-breakpoint-down(lg) {
        position: relative;
        top: 50px;
    }
}
h1, h2, h3 {
    font-family: 'Raleway', sans-serif;
    font-weight: 100;
    &.fw-bolder {
        text-shadow: 0 0 black;
    }
}
.text-justify {
    text-align: justify;
}
svg {
    &.svg-inline--fa {
        display: inline-block;
        height: 1em;
    }
}
.btn.btn-link, .btn.btn-icon {
    &, &:focus, &:active {
        border: none;
        box-shadow: none;
        outline: 0;
    }
}
.btn:hover > svg.bi-trash-fill {
    fill: var(--bs-danger);
}
.table {
    font-size: 14px;
}
[role="link"] {
    cursor: pointer;
}
[role="link"]:hover {
    color: var(--bs-primary);
}
/**
 * OFFCANVAS
 */
.offcanvas {
    background-color: #ffffff;
    transition: background-color 1s ease-in-out, visibility .3s ease-in-out, transform .3s ease-in-out;
    &.show {
        background-color: var(--bs-primary);
        transition: background-color 0s ease-in-out, visibility .3s ease-in-out, transform .3s ease-in-out;
    }
}
/**
 * TOAST
 */
.toast-container {
    position: fixed;
}
/**
 * PANEL
 */
.panel {
    &-heading {
        .btn-link {
            font-weight: 700;
        }
    }
    &-open {
        .panel {
            &-heading {
                background-color: rgba(var(--bs-primary-rgb), 1);
                .btn-link {
                    color: #FFF;
                }
            }
        }
    }
}
.session-panel {
    .panel-heading {
        background-color: color(primary);
        color: #ffffff;
    }
    .panel-body .card-body {
        font-size: 1rem;
    }
}
form.is-full {
    .img-wrapper.full {
        &:before {
            align-items: center;
            background-color: rgba(var(--bs-danger-rgb), 0.8);
            border-top-right-radius: 2px;
            border-bottom-right-radius: 2px;
            box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
            color: #FFFFFF;
            content: 'COMPLET';
            display: flex;
            font-size: 30px;
            font-weight: 500;
            height: 60px;
            justify-content: center;
            left: 0;
            letter-spacing: 1px;
            line-height: 20px;
            margin-left: calc(var(--bs-gutter-x) * 0.5);
            padding: 10px 15px;
            position: absolute;
            top: 66%;
            width: 60%;
            z-index: 5;
        }
    }
    .session-panel {
        .panel-heading {
            background-color: var(--bs-danger);
        }
    }
}
/**
 * CAROUSEL
 */
.owl-carousel {
    &.owl-theme {
        .owl-stage-outer {
            padding: 20px 0;
            margin-bottom: 50px;
        }
        .owl-stage {
            align-items: center;
            display: flex;
        }
        .owl-nav [class*=owl-] {
            background: transparent;
            &:active, &:hover {
                background: transparent;
            }
        }
        @include media-breakpoint-down(lg) {
            .owl-nav.disabled + .owl-dots {
                margin-top: -50px;
            }
        }
        .card-show {
            height: 500px;
            margin: 0 auto;
            max-width: 285px;
            min-width: 200px;
            width: 100%;
        }
    }
}
.carousel {
    &-control {
        &-prev, &-next {
            opacity: 1;
            width: auto;
            &-icon {
                background-color: color(gray-900);
                background-size: 50% 100%;
                border-radius: 50%;
                color: color(white);
                padding: 1.5rem;
            }
        }
        &-prev-icon {
            background-image: url('data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 16 16\' fill=\'%23ffffff\'%3e%3cpath d=\'M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z\'/%3e%3c/svg%3e');
        }
        &-next-icon {
            background-image: url('data:image/svg+xml,%3csvg xmlns= \'http://www.w3.org/2000/svg\' viewBox= \'0 0 16 16\' fill= \'%23ffffff\' %3e%3cpath d= \'M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z\' /%3e%3c/svg%3e');
        }
    }
}
/**
 * FORM
 */
.required {
    &:after {
        content: '*';
        color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity))
    }
}
/**
 * CARD
 */
.card {
    --bs-card-border-color: transparent;
    --bs-card-border-radius: 0;
    &-show {
        --bs-card-box-shadow: 0px .2rem .5rem rgba(0, 0, 0, .15);
        box-shadow: var(--bs-card-box-shadow);
        height: 500px;
        transform: scale(1);
        transition: all ease .5s;
        max-width: 285px;
        min-width: 285px;
        width: 100%;
        @include media-breakpoint-up(lg) {
            max-width: 300px;
            min-width: 300px;
        }
        &.full {
            overflow: hidden;
            &:before {
                background: rgba(black, 0.6);
                content: '';
                display: block;
                height: 100%;
                left: 0;
                opacity: 0;
                position: absolute;
                top: 0;
                transition: all ease 0.5s;
                width: 100%;
                z-index: 2;
            }
            .card-btn {
                transition: all ease 0.3s;
            }
            .btn-is-full {
                align-items: center;
                border-radius: 50% !important;
                bottom: 0;
                box-shadow: 0px 0px 7px 2px rgba(0, 0, 0, .6);
                display: flex;
                font-size: 16px;
                font-weight: 700;
                height: 100px;
                justify-content: center;
                left: 0;
                letter-spacing: 1px;
                margin: auto;
                opacity: 0;
                position: absolute;
                right: 0;
                top: 0;
                transform: translateX(200px);
                transition: all ease .7s;
                width: 100px;
                z-index: 15;
            }
        }
        &:hover {
            transform: scale(1.05);
            &.full {
                .card-btn {
                    opacity: 0;
                    transform: translateX(200px);
                }
                .btn-is-full {
                    opacity: .9;
                    transform: translateX(0px);
                }
                &:before {
                    opacity: 1;
                }
            }
        }
        .show-img {
            height: 380px;
        }
        .card-body {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            min-height: 120px;
        }
    }
    &-body {
        font-size: 12px;
    }
    &-title {
        font-size: 16px;
        font-weight: 700;
        line-height: 16px;
        margin-bottom: 10px;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        text-wrap: nowrap;
    }
}
.show {
    &-preview {
        max-height: 380px;
        @include media-breakpoint-down(sm) {
            max-height: 280px;
        }
    }
    &-img {
        height: 380px;
        @include media-breakpoint-down(sm) {
            height: 280px;
        }
    }
    &-title {
        height: 60px;
    }
}
/**
 * SEARCH
 */
.search-block {
    width: 100%;
    @include media-breakpoint-up(lg) {
        width: 75%;
    }
}
