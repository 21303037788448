.color {
    @each $name in $colorNames {
        &-#{"" + $name} {
            color: color($name) !important;
        }
    }
}
.color-hover {
    @each $name in $colorNames {
        &-#{"" + $name} {
            &:hover {
                color: color($name) !important;
            }
        }
    }
}
