.loading-container {
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    height: 100vh;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 300;
    .spinner-border {
        color: #fff;
        height: calc(100vh / 3);
        position: relative;
        width: calc(100vh / 3);
        z-index: 310;
    }
}
.spinner {
    display: flex !important;
    justify-content: center;
    align-items: center;
    padding: 0 !important;
    animation: rotating 2s linear infinite;
}
@keyframes rotating {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
.btn-spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    .spinner-border {
        position: absolute;
    }
}
