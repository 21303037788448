/**
 * Colors
 */
$colorNames: primary, white, deep-white, black, gray-200, gray-400, gray-900, danger;
$primary: #6d7c83;
$custom-grey-1: #3f3f3f;
$custom-grey-2: #333;
$colors: (
    primary: $primary,
    white: #fff,
    deep-white: #f6f3f1,
    black: #000,
    gray-200: #e9ecef,
    gray-400: #ced4da,
    gray-900: #212529,
    danger: #CC3333
);
/**
 * Breakpoints
 */
$formats: xs, sm, md, lg, xl, xxl;
$breakpoints: (
    xs: 420px,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px
);
$containers: (
    xs: 100%,
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1320px
);
/**
 * Header
 */
$header_height: 90px;
$navbar-padding-y: null;
/**
 * Layout
 */
$layout_height: 100vh;
/**
 * Footer
 */
$footer_height: 350px;
$footer_padding_top: 35px;
$footer_padding_bottom: 1rem;
/**
 * Components
 */
// Toolbar
$toolbarHeight: 54px;
// Popover
$popover-header-bg: $primary;
$popover-header-color: map-get($colors, white);
// Carousel
$carousel-control-color: $primary;
:root {
    --bs-primary: #{$primary};
}
